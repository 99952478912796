import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlocksParagraph = styled.p<ContentBlocksParagraphProps>`
  font-size: 4vw;
  line-height: 130%;
  margin: ${({ mobileMargin }) => mobileMargin ? mobileMargin : '5vw 0'};

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth}%;
  `}

  > a {
    text-decoration: underline;
  }

  ${media.tablet`
    font-size: 2.5vw;
  `}

  ${media.desktop<ContentBlocksParagraphProps>`
    line-height: 1.9vw;
    font-size: 1.5vw;
    margin: ${({ desktopMargin }) => desktopMargin ? desktopMargin : '3.5vw 0'};
  `}
`;

type ContentBlocksParagraphProps = {
  maxWidth?: string;
  desktopMargin?: string;
  mobileMargin?: string;
};
