import styled from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlocksFooterContainer = styled.div`
  margin-top: -20px;

  .desktop_image {
    display: none;
  }

  ${media.desktop`
    .desktop_image {
      display: block;
      max-height: 90vh;
    }

    .mobile_image {
      display: none;
    }
  `}
`;
