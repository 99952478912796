import styled from 'styled-components';

import { getColor } from 'styles/utils';
import { BackgroundColors } from './';

export const RoundedBottomCornersContainer = styled.div<RoundedBottomCornersContainerProps>`
  height: 2vw;
  position: relative;
  background-color: ${({ theme, $bgColor }) => $bgColor ? getColor($bgColor) : theme.colors.woodSmoke};
`;

type RoundedBottomCornersContainerProps = {
  $bgColor?: BackgroundColors;
};

export const RoundedBottomCornersInner = styled.div<RoundedBottomCornersInnerProps>`
  background-color: ${({ theme, $innerColor }) => $innerColor ? getColor($innerColor) : theme.colors.springWood};
  height: 2vw;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
`;

type RoundedBottomCornersInnerProps = {
  $innerColor?: BackgroundColors;
};
