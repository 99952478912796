import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlocksTitle = styled.h2<ContentBlocksTitleProps>`
  font-size: 6vw;
  line-height: 100%;
  font-family: ${({ theme, variant }) => variant === 'display' ? theme.fonts.oggRoman : theme.fonts.suisseIntl};
  font-weight: 400;
  margin: 0 0 2vw;

  ${media.desktop`
    font-size: 3vw;
  `}

  ${({ margin }) => margin && css`
    margin: ${margin};
  `}

  ${({ uppercase }) => uppercase && css`
    text-transform: uppercase;
  `}

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth}%;
  `}

  ${({ isCentered }) => isCentered && css`
    text-align: center;
  `}
`;

type ContentBlocksTitleProps = {
  uppercase?: boolean;
  maxWidth?: string;
  margin?: string;
  variant?: 'heading' | 'display';
  isCentered?: boolean;
};
