import styled from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlocksList = styled.ul`
  margin: 5vw 0;

  ${media.desktop`
    margin: 3.5vw 0;
  `}
`;
