import styled from 'styled-components';

import { Heading, Text } from 'common/typography';
import { media } from 'styles/utils';

export const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.heronPlume};
  position: relative;
`;

export const HeaderMainWrapper = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  ${media.tablet`
    height: 80vw;
    max-height: 90vh;
  `}

  ${media.desktop`
    height: 60vw;
  `}
`;

export const HeaderTitle = styled(Heading)`
  font-size: 12vw;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  font-weight: 400;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${media.tablet`
    font-size: 13vw;
  `}

  ${media.desktop`
    font-size: 10vw;
  `}
`;

export const HeaderImageContainer = styled.div`
  width: 100%;
  height: 100%;

  .header_image_desktop, .header_image_mobile {
    user-select: none;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }

  .header_image_desktop {
    display: none;
  }

  ${media.desktop`
    .header_image_desktop {
      display: block;
    }

    .header_image_mobile {
      display: none;
    }
  `}
`;

export const HeaderDoubleImageContainer = styled.div`
  display: flex;
  width: 80%;

  .header_image {
    width: 80vw;
    height: 80vw;
    border-radius: 20px;
    user-select: none;
    pointer-events: none;
  }

  ${media.tablet`
    width: 65%;

    .header_image {
      width: 32.5vw;
      height: 32.5vw;
    }

    & > div:first-of-type {
      margin-right: 1vw;
    }
  `}

  ${media.desktop`
    width: 50%;

    .header_image {
      width: 25vw;
      height: 25vw;
    }
  `}
`;

export const HeaderImageWrapper = styled.div`
  display: none;

  ${media.tablet`
    display: block;
  `}
`;
