import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlocksListItem = styled.li<ContentBlocksListItemProps>`
  font-size: 4vw;
  line-height: 130%;

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth}%;
  `}

  > a {
    text-decoration: underline;
  }

  ${media.tablet`
    font-size: 2.5vw;
  `}

  ${media.desktop`
    line-height: 1.9vw;
    font-size: 1.5vw;
  `}
`;

type ContentBlocksListItemProps = {
  maxWidth?: string;
};
