import styled, { css } from 'styled-components';
import * as i from 'types';

import { Text } from 'common/typography';
import { media } from 'styles/utils';

export const DoubleBlocksWrapper = styled.div<DoubleBlocksWrapperProps>`
  margin: ${({ margin }) => margin ? margin : '2vw 0'};
  width: 100%;
  padding: 0 2vw;
`;

type DoubleBlocksWrapperProps = {
  margin?: string;
};

export const DoubleBlock = styled.div<DoubleBlockProps>`
  background-color: ${({ theme, color }) => theme.colors[color || 'colonnadeStone']};
  border-radius: 20px;
  width: 100%;
  height: ${({ fluidHeigthMobile }) => fluidHeigthMobile ? 'auto' : '96vw'};
  flex-shrink: 1;

  ${media.desktop`
    height: 47vw;
    width: 100%;
  `}
`;

type DoubleBlockProps = {
  color?: i.ThemeColors;
  fluidHeigthMobile?: boolean;
};

export const DoubleBlockImage = styled(DoubleBlock)`
  position: relative;

  .double-image-block_first-image, .double-image-block_second-image {
    width: 100%;
    height: 96vw;
    border-radius: 20px;
    pointer-events: none;
    user-select: none;
  }

  ${media.desktop`
    .double-image-block_first-image, .double-image-block_second-image {
      max-height: calc(100vh - 62px);
      width: 100%;
      height: 47vw;
    }
  `}
`;

export const DoubleBlockExtendedTextBottom = styled(Text)`
  position: absolute;
  z-index: 1;
  left: 14px;
  right: 14px;
  text-align: center;
  line-height: 100%;
  bottom: 20px;
  font-size: 4vw;

  ${media.tablet`
    font-size: 1.5vw;
    left: 74px;
    right: 74px;
    bottom: 40px;
  `}

  ${media.desktop`
    font-size: 1.2vw;
    text-align: left;
  `}
`;

export const DoubleBlockText = styled(DoubleBlock)<DoubleBlockTextProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15%;
  text-align: center;
  flex-direction: column;
  font-size: 4vw;
  line-height: 130%;

  ${({ textAlign }) => textAlign && css`
    text-align: ${textAlign};
  `}

  ${media.tablet`
    font-size: 2.5vw;
    padding: 10%;
  `}

  ${media.desktop`
    line-height: 1.9vw;
    font-size: 1.5vw;
    padding: 8%;
  `}
`;

type DoubleBlockTextProps = DoubleBlockProps & {
  textAlign?: 'center' | 'left' | 'right';
};

export const DoubleBlockSvg = styled(DoubleBlock)<DoubleBlockProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 70%;

    ${media.tablet`
      width: 60%;
    `}

    ${media.desktop`
      width: 50%;
    `}
  }
`;

export const DoubleBlocksContainer = styled.div`
  width: 100%;
  width: 100%;
  display: grid;
  grid-gap: 2vw;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  ${media.desktop`
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  `}
`;
