import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlocksSection = styled.section<ContentBlocksSectionProps>`
  margin: 10vw 2vw;

  ${media.desktop`
    margin: 6vw 2vw;
  `}

  ${({ margin }) => margin?.mobile && css`
    margin: ${`${margin.mobile}`};
  `}

  ${({ margin }) => margin?.desktop && media.desktop`
    margin: ${`${margin.desktop}`};
  `}
`;

type ContentBlocksSectionProps = {
  margin?: {
    mobile?: string;
    desktop?: string;
  }
}
