import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { Text } from 'common/typography';
import { ContentBlocksSection } from 'modules/contentBlocks';

export const TextAndImageBlockExtraFieldsContainer = styled(ContentBlocksSection)<TextAndImageBlockExtraFieldsProps>`
  display: flex;
  flex-direction: column-reverse;

  ${({ isReversedMobile }) => isReversedMobile && css`
    flex-direction: column;
  `}

  ${media.desktop<TextAndImageBlockExtraFieldsProps>`
    flex-direction: row;

    ${({ isReversed }) => isReversed && css`
      flex-direction: row-reverse;
    `}
  `}
`;

type TextAndImageBlockExtraFieldsProps = {
  isReversed?: boolean;
  isReversedMobile?: boolean;
};

export const TextAndImageBlockExtraFieldsImage = styled.div<TextAndImageBlockExtraFieldsImageProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ alignLeft }) => alignLeft ? 'left' : 'right'};
  margin-top: 6vw;

  ${media.desktop`
    width: 49vw;
    margin-top: 0;
  `}

  .watch_deconstructed_image {
    border-radius: 20px;
    user-select: none;
    pointer-events: none;

    ${media.desktop`
      width: 35vw;
    `}
  }

  .text-and-image-extra-fields-block_image {
    width: 96vw;
    height: 96vw;
    user-select: none;
    pointer-events: none;

    ${media.desktop`
      width: 40vw;
      height: 40vw;
    `}
  }
`;

type TextAndImageBlockExtraFieldsImageProps = {
  alignLeft?: boolean;
};

export const TextAndImageBlockExtraFieldsImageText = styled.div`
  margin-top: 6vw;

  ${media.desktop`
    margin: 0 0 20vw;
    width: 49vw;
  `}

  & > p:last-of-type {
    opacity: 0.45;
    margin-top: 3%;
  }
`;

export const TextAndImageBlockExtraFieldsImageSentence = styled(Text)`
  font-size: 8vw;
  line-height: 100%;
  font-weight: 400;
  text-transform: uppercase;

  ${media.tablet`
    font-size: 5vw;
  `}

  ${media.desktop`
    font-size: 2.5vw;
    margin: 0 14vw 0.5vw 6vw;
  `}
`;

export const TextAndImageBlockExtraFieldsText = styled.div<TextAndImageBlockExtraFieldsTextProps>`
  display: flex;
  justify-content: ${({ alignText }) => alignText || 'center'};
  flex-direction: column;
  align-items: flex-start;

  ${media.desktop`
    width: 49vw;
  `}

  ${({ textOnly, small }) => textOnly && css`
    ${media.desktop`
      width: ${small ? '35vw' : '65vw'};
    `}
  `}

  ${({ textOnly, wide }) => textOnly && css`
    ${media.desktop`
      width: ${wide ? '75vw' : '65vw'};
    `}
  `}
`;

type TextAndImageBlockExtraFieldsTextProps = {
  alignText?: 'flex-start' | 'center' | 'flex-end';
  small?: boolean;
  textOnly?: boolean;
  wide?: boolean;
};

export const TextAndImageBlockExtraFieldsHeading = styled.h3`
  font-size: 8vw;
  text-transform: uppercase;
  margin: 0 0 2vw;

  ${media.desktop`
    font-size: 1.5vw;
    margin: 0 0 2vw;
  `}
`;
