import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlocksContainer = styled.div<ContentBlocksContainerProps>`
  background-color: ${({ theme }) => theme.colors.springWood};
  border-radius: 20px;
  margin: 0 0 -20px 0;

  ${({ $noHeader }) => $noHeader && css`
    margin-top: 46px;

    ${media.desktop`
      margin-top: 62px;
    `}
  `}
`;

type ContentBlocksContainerProps = {
  $noHeader?: boolean;
}
