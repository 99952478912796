import styled from 'styled-components';

import { media } from 'styles/utils';
import { ContentBlocksTitle } from 'modules/contentBlocks';

export const ImageWithTextContainer = styled.section`
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  margin: 2vw;
  height: 70vh;

  ${media.desktop`
    flex-direction: row;
    align-items: center;
  `}
`;

export const ImageWithTextImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

export const ImageWithTextTitle = styled(ContentBlocksTitle)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;
