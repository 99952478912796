import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const TextAndImageBlockContainer = styled.section<TextAndImageBlockContainerProps>`
  display: flex;
  flex-direction: column-reverse;
  margin: 6vw 4vw;

  ${media.desktop<TextAndImageBlockContainerProps>`
    margin: 2vw 5%;
    flex-direction: row;

    ${({ isReversed }) => isReversed && css`
      flex-direction: row-reverse;
    `}
  `}
`;

type TextAndImageBlockContainerProps = {
  isReversed?: boolean;
};

export const TextAndImageBlockImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6vw;

  ${media.desktop`
    margin-top: 0;
    width: 42vw;
  `}

  .text-and-image-block_image {
    width: 100vw;
    height: 100%;
    user-select: none;
    pointer-events: none;

    ${media.desktop`
      width: 40vw;
    `}
  }
`;

export const TextAndImageBlockText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.desktop`
    width: 56vw;
  `}
`;
