import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { Text } from 'common/typography';
import { ContentBlocksSection, ContentBlocksParagraph } from 'modules/contentBlocks';

export const QuoteWithTextContainer = styled(ContentBlocksSection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const QuoteWithTextQuote = styled.div<QuoteWithTextQuoteProps>`
  ${media.desktop`
    margin: 0 0 2vw;
  `}

  & > p:nth-of-type(2) {
    opacity: 0.45;
    margin-top: 1%;
  }

  & > p:nth-of-type(3) {
    opacity: 0.35;
    margin-top: 1%;
  }

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth}%;
  `}
`;

type QuoteWithTextQuoteProps = {
  maxWidth?: string;
}

export const QuoteWithTextSentence = styled(Text)`
  font-size: 8vw;
  line-height: 100%;
  text-transform: uppercase;

  ${media.tablet`
    font-size: 4vw;
  `}

  ${media.desktop`
    font-size: 3vw;
    margin: 0;
  `}
`;

export const QuoteWithTextParagraph = styled(ContentBlocksParagraph)<QuoteWithTextParagraphProps>`
  ${media.desktop<QuoteWithTextParagraphProps>`
    max-width: 30vw;

    ${({ maxWidth }) => maxWidth && css`
      max-width: ${maxWidth}%;
    `}
  `}
`;

type QuoteWithTextParagraphProps = {
  maxWidth?: string;
}
