import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const TextWithBackgroundBlockContainer = styled.section<TextWithBackgroundBlockContainerProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin: 2vw;

  ${media.desktop<TextWithBackgroundBlockContainerProps>`
    height: 40vw;
    flex-direction: row;
    align-items: center;
    padding: 0 3.5vw;
    border-radius: 20px;
  `}

  ${({ isReverse }) => isReverse && css`
    ${media.desktop`
      flex-direction: row-reverse;
    `}
  `}
`;

type TextWithBackgroundBlockContainerProps = {
  smallMargin?: boolean;
  isReverse?: boolean;
}

export const TextWithBackgroundBlockImage = styled.div`
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
  display: none;

  ${media.desktop`
    display: flex;
  `}

  .text-with-background-block_image {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
  }
`;

export const TextWithBackgroundBlockImageMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.desktop`
    display: none;
  `}

  .text-with-background-block_image {
    height: 96vw;
    width: 96vw;
    border-radius: 20px;
  }
`;

export const TextWithBackgroundBlockText = styled.div<TextWithBackgroundBlockTextProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10vw 0 5vw;
  z-index: 1;

  ${media.desktop<TextWithBackgroundBlockTextProps>`
    width: 45%;
    padding: 10vw 0;
    margin: ${({ alignLeft }) => alignLeft ? '0 55% 0 0' : '0 0 0 55%'};
  `}
`;

type TextWithBackgroundBlockTextProps = {
  alignLeft?: boolean;
}
