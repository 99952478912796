import styled from 'styled-components';

import { media } from 'styles/utils';
import { Heading } from 'common/typography';
import { Button } from 'common/interaction';

export const HeaderWithVideoContainer = styled.div`
  position: relative;
  margin-top: 46px;
  max-height: 90vh;

  ${media.desktop`
    margin-top: 62px;
  `}
`;

export const HeaderWithVideoTitle = styled(Heading)`
  font-size: 12vw;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;

  ${media.tablet`
    font-size: 13vw;
  `}

  ${media.desktop`
    font-size: 10vw;
  `}
`;

export const HeaderWithVideoVideo = styled.video`
  max-height: 90vh;
  width: 100%;
  object-fit: cover;

  ${media.tablet`
    max-height: 42vw;
  `}
`;

export const HeaderWithVideoPausedWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderWithVideoPlayButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  margin: 2vw auto 0;
`;
