import styled from 'styled-components';

import { media } from 'styles/utils';
import { Heading } from 'common/typography';
import { Button } from 'common/interaction';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0vw;
  overflow-x: hidden;
  gap: 32px;
  background-color: ${({ theme }) => theme.colors.sand.lighter};
  position: relative;

  ${media.desktop`
    height: 100vw;
    gap: 120px;
    height: 100%;
  `}

  ${media.large`
    gap: 140px;
  `}
`;

export const TakeControlContainer = styled(Container)`
  > div {
    left: 0;
    bottom: 0;
  }

  ${media.desktop`
    h1 {
      width: 60%;
    }
  `}
`;

export const HomeIntegrateMindfulnessContainer = styled(Container)`
  > div {
    left: auto;
    bottom: 0;
  }
  h1 {
    color: ${({ theme }) => theme.colors.sand.lighter};
  }
`;

export const ContentContainer = styled.div`
  position: absolute;
  margin: 50px;
`;

export const ExtendedHeading = styled(Heading)`
  font-weight: 400;
  font-size: 48px;
  line-height: 100%;
  font-family: ${({ theme }) =>  theme.fonts.suisseIntl};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.sand.lighter};
  text-transform: none;

  ${media.desktop`
    font-size: 64px;
    margin: 0 0 30px;
  `}
`;

export const ExtendedButton = styled(Button)`
  height: auto;
  padding: 14px 24px;
`;
